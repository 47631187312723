import styled, { css } from 'styled-components'
import { MarginType, PaddingType } from 'common/types/styleTypes'
import {
  paddingsCss,
  fontCss,
  mobilePaddingsCss,
  FontProps,
} from 'common/utils/styleUtilsNew'

export const CommonBookingCalendarWrapperUi = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

interface CommonCalendarContainerUiProps extends FontProps {
  padding: PaddingType
  margin: MarginType
}

export const CommonBookingCalendarContainerUi = styled.div<CommonCalendarContainerUiProps>`
  max-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 6px;

  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  ${paddingsCss}
  ${fontCss}

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      ${mobilePaddingsCss}
      flex-direction: column;
    `}
  ${p => p.theme.phone} {
    ${mobilePaddingsCss}
    flex-direction: column;
  }
`
