import { useRollbar } from '@rollbar/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { optInFail } from 'publisher/actions/optInActions'
import * as api from 'publisher/api/paypal'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import loadScript from 'publisher/utils/loadScript'
import usePaymentSubmit from './usePaymentSubmit'

export default function usePaypalPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const rollbar = useRollbar()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const [isScriptLoading, setScriptLoading] = useState(true)
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)

  useEffect(() => {
    loadScript(
      'https://www.paypalobjects.com/api/checkout.min.js',
      'paypal-js',
      () => {
        //@ts-ignore
        window.paypal.checkout.setup('merchant', {
          environment: process.env.PAYPAL_ENV,
        })
        setScriptLoading(false)
      },
    )
  }, [])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      try {
        await api.validateForm(pageId, body, purchaseProcessId)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors([
            ...error.response.data.errors.common,
            t('core.errors.bad_request'),
          ])
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.captureEvent(body, 'debug')
          rollbar.error('Paypal SOAP validateForm error', error as Error)
        }

        // initializes paypal payment modal
        // @ts-ignore
        window.paypal.checkout.initXO()

        // @ts-ignore
        window.paypal.checkout.closeFlow()
        return
      }

      try {
        const calculatedFormProperties = body.product
          ? {
              product: {
                id: body.product.id,
                variant: body.product.variant,
                quantity: body.product.quantity,
              },
            }
          : {
              offerPricePlan: body.offerPricePlan,
            }

        const { data } = await api.purchaseDetails(
          pageId,
          calculatedFormProperties,
          purchaseProcessId,
        )

        if (data.redirect) {
          // @ts-ignore
          window.paypal.checkout.closeFlow()
          window.location.assign(data.redirect)
          return
        }

        // @ts-ignore
        window.paypal.checkout.startFlow(data.approvalUrl)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors([
            ...error.response.data.errors.common,
            t('core.errors.bad_request'),
          ])
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          setErrors([t('core.error.title')])
          rollbar.captureEvent(body, 'debug')
          rollbar.error('Paypal SOAP error', error as Error)
        }
        // @ts-ignore
        window.paypal.checkout.closeFlow()
      }
    })
  }

  return { errors, isLoading, handleSubmit, isScriptLoading }
}
