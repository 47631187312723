import React from 'react'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import { BookingCalendarSteps } from './constants'
import {
  CommonBookingCalendarStepsUi,
  CommonBookingCalendarStepItemUi,
} from './ui/common-calendar-steps-ui'

interface BookingCalendarStepsSwitcherProps {
  activeStep: BookingCalendarSteps
  entity: BookingCalendarInterface
  onPrevStepClick?: () => void
  onNextStepClick?: () => void
}

export const BookingCalendarStepsSwitcher = ({
  entity,
  activeStep,
  onPrevStepClick,
  onNextStepClick,
  ...stylesProps
}: BookingCalendarStepsSwitcherProps & FontProps) => {
  return (
    <CommonBookingCalendarStepsUi>
      <CommonBookingCalendarStepItemUi
        isActive={activeStep === BookingCalendarSteps.CalendarStep}
        activeColor={entity.selectedSlotBackgroundColor}
        color={
          activeStep === BookingCalendarSteps.CalendarStep
            ? entity.titlesColor
            : entity.color
        }
        style={{
          cursor:
            activeStep === BookingCalendarSteps.FormStep
              ? 'pointer'
              : 'default',
        }}
        onClick={() => {
          onPrevStepClick?.()
        }}
        {...stylesProps}
      >
        <span>{entity.calendarStepHeadline}</span>
        <p>{entity.calendarStepSubHeadline}</p>
      </CommonBookingCalendarStepItemUi>
      <CommonBookingCalendarStepItemUi
        isActive={activeStep === BookingCalendarSteps.FormStep}
        activeColor={entity.selectedSlotBackgroundColor}
        color={
          activeStep === BookingCalendarSteps.FormStep
            ? entity.titlesColor
            : entity.color
        }
        onClick={() => {
          onNextStepClick?.()
        }}
        style={{
          cursor:
            activeStep === BookingCalendarSteps.CalendarStep
              ? 'pointer'
              : 'default',
        }}
        {...stylesProps}
      >
        <span>{entity.formStepHeadline}</span>
        <p>{entity.formStepSubHeadline}</p>
      </CommonBookingCalendarStepItemUi>
    </CommonBookingCalendarStepsUi>
  )
}
