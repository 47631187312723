import styled, { css } from 'styled-components'

export const TimeSlotsWrapperUi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  width: 100%;

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      max-width: 100%;
      margin-top: 40px;
    `}
  ${p => p.theme.phone} {
    max-width: 100%;
    margin-top: 40px;
  }
`
