import React from 'react'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { FontProps } from 'common/utils/styleUtilsNew'
import {
  AvailabilitySlot,
  EventLocationInterface,
  EventInterface,
} from '../calendar-interface'
import { DayPicker } from './day-picker'
import { EventInfo } from './event-info'
import { TimeSlots } from './time-slots'
import { CommonCalendarUi } from './ui/common-calendar-ui'

export interface CalendarStepProps {
  entity: BookingCalendarInterface

  availabilitySlots: AvailabilitySlot[]
  selectedDate?: Date
  onDateSelect?: (date: Date) => void
  selectedTimeSlot?: number
  onTimeSlotSelect?: (timeSlot: number) => void
  selectedTimeZone: string
  onTimeZoneSelect?: (timeZone: string) => void
  selectedMonth?: Date
  onMonthSelect?: (month: Date) => void
  selectedLocationId?: EventLocationInterface['id']
  onLocationSelect?: (locationId: EventLocationInterface['id']) => void
  onNextStepClick?: () => void

  event?: EventInterface
  isLoading?: boolean
}

export const CalendarStep = ({
  availabilitySlots,
  selectedDate,
  selectedMonth,
  onMonthSelect,
  onDateSelect,
  selectedTimeSlot,
  onTimeSlotSelect,
  selectedTimeZone,
  onTimeZoneSelect,
  selectedLocationId,
  onLocationSelect,
  event,
  isLoading,
  entity,
}: CalendarStepProps & FontProps) => {
  return (
    <CommonCalendarUi>
      <EventInfo
        {...event}
        selectedTimeZone={selectedTimeZone}
        onTimeZoneSelect={onTimeZoneSelect}
        titlesColor={entity.titlesColor}
        mobileTitlesColor={entity.mobileTitlesColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        selectedSlotBackgroundColor={entity.selectedSlotBackgroundColor}
        selectedLocationId={selectedLocationId}
        onLocationSelect={onLocationSelect}
        isLoading={isLoading}
      />
      <DayPicker
        selectedDate={selectedDate}
        onDateSelect={onDateSelect}
        selectedMonth={selectedMonth}
        onMonthSelect={onMonthSelect}
        isLoading={isLoading}
        availableSlotBackgroundColor={entity.availableSlotBackgroundColor}
        selectedSlotBackgroundColor={entity.selectedSlotBackgroundColor}
        color={entity.color}
        mobileColor={entity.mobileColor}
        titlesColor={entity.titlesColor}
        mobileTitlesColor={entity.mobileTitlesColor}
        availabilitySlots={availabilitySlots}
      />
      {selectedDate && (
        <TimeSlots
          availabilitySlots={availabilitySlots}
          onTimeSlotSelect={onTimeSlotSelect}
          selectedTimeSlot={selectedTimeSlot}
          selectedSlotBackgroundColor={entity.selectedSlotBackgroundColor}
          selectedDate={selectedDate}
        />
      )}
    </CommonCalendarUi>
  )
}
