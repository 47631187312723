export enum EventLocationTypeEnum {
  OnlineMeeting = 'ONLINE_MEETING',
  Personal = 'PERSONAL',
  Phone = 'PHONE',
  Zoom = 'ZOOM',
}

export enum EventTimeZoneTypeEnum {
  Fixed = 'FIXED',
  Local = 'LOCAL',
}

export const weekdaysMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const weekdayShortLocoKeys = {
  [weekdaysMap.SUNDAY]: 'components.core.calendar.weekdays.sunday_short',
  [weekdaysMap.MONDAY]: 'components.core.calendar.weekdays.monday_short',
  [weekdaysMap.TUESDAY]: 'components.core.calendar.weekdays.tuesday_short',
  [weekdaysMap.WEDNESDAY]: 'components.core.calendar.weekdays.wednesday_short',
  [weekdaysMap.THURSDAY]: 'components.core.calendar.weekdays.thursday_short',
  [weekdaysMap.FRIDAY]: 'components.core.calendar.weekdays.friday_short',
  [weekdaysMap.SATURDAY]: 'components.core.calendar.weekdays.saturday_short',
}

export const getReadableShortWeekdayName = (day: Date | number) => {
  if (typeof day === 'number') return weekdayShortLocoKeys[day]
  return weekdayShortLocoKeys[day.getDay()]
}

const eventLocationLabels = {
  [EventLocationTypeEnum.Phone]:
    'components.core.calendar.event_location.phone',
  [EventLocationTypeEnum.Personal]:
    'components.core.calendar.event_location.personal',
  [EventLocationTypeEnum.OnlineMeeting]:
    'components.core.calendar.event_location.online_meeting',
  [EventLocationTypeEnum.Zoom]: 'components.core.calendar.event_location.zoom',
}

export const getReadableEventLocation = (location: EventLocationTypeEnum) => {
  return eventLocationLabels[location]
}

const monthsLocoKeys = {
  january: 'components.core.calendar.months.january',
  february: 'components.core.calendar.months.february',
  march: 'components.core.calendar.months.march',
  april: 'components.core.calendar.months.april',
  may: 'components.core.calendar.months.may',
  june: 'components.core.calendar.months.june',
  july: 'components.core.calendar.months.july',
  august: 'components.core.calendar.months.august',
  september: 'components.core.calendar.months.september',
  october: 'components.core.calendar.months.october',
  november: 'components.core.calendar.months.november',
  december: 'components.core.calendar.months.december',
}

export const getReadableMonthName = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'long' })
  return monthsLocoKeys[month.toLowerCase() as keyof typeof monthsLocoKeys]
}

export const timeZones = [
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Anchorage',
  'America/Phoenix',
  'America/St_Johns',
  'Pacific/Honolulu',
  'America/Adak',
  'America/Argentina/Buenos_Aires',
  'America/Asuncion',
  'America/Bogota',
  'America/Campo_Grande',
  'America/Caracas',
  'America/Godthab',
  'America/Goose_Bay',
  'America/Guatemala',
  'America/Havana',
  'America/Mazatlan',
  'America/Mexico_City',
  'America/Montevideo',
  'America/Miquelon',
  'America/Noronha',
  'America/Santiago',
  'America/Santa_Isabel',
  'America/Santo_Domingo',
  'America/Sao_Paulo',
  'Africa/Casablanca',
  'Africa/Cairo',
  'Africa/Johannesburg',
  'Africa/Lagos',
  'Africa/Windhoek',
  'Asia/Amman',
  'Asia/Baghdad',
  'Asia/Baku',
  'Asia/Beirut',
  'Asia/Damascus',
  'Asia/Dhaka',
  'Asia/Dubai',
  'Asia/Gaza',
  'Asia/Irkutsk',
  'Asia/Jakarta',
  'Asia/Jerusalem',
  'Asia/Kabul',
  'Asia/Kamchatka',
  'Asia/Karachi',
  'Asia/Kathmandu',
  'Asia/Kolkata',
  'Asia/Krasnoyarsk',
  'Asia/Omsk',
  'Asia/Rangoon',
  'Asia/Shanghai',
  'Asia/Tehran',
  'Asia/Tokyo',
  'Asia/Vladivostok',
  'Asia/Yakutsk',
  'Asia/Yekaterinburg',
  'Asia/Yerevan',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'Australia/Adelaide',
  'Australia/Brisbane',
  'Australia/Darwin',
  'Australia/Eucla',
  'Australia/Lord_Howe',
  'Australia/Perth',
  'Australia/Sydney',
  'UTC',
  'Europe/Berlin',
  'Europe/Helsinki',
  'Europe/London',
  'Europe/Minsk',
  'Europe/Moscow',
  'Europe/Chisinau',
  'Europe/Istanbul',
  'Pacific/Apia',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Easter',
  'Pacific/Fiji',
  'Pacific/Gambier',
  'Pacific/Kiritimati',
  'Pacific/Majuro',
  'Pacific/Marquesas',
  'Pacific/Norfolk',
  'Pacific/Noumea',
  'Pacific/Pago_Pago',
  'Pacific/Pitcairn',
  'Pacific/Tarawa',
  'Pacific/Tongatapu',
  'Africa/Nairobi',
  'America/Argentina/San_Luis',
  'America/Cayenne',
  'America/Eirunepe',
  'America/Guayaquil',
  'America/Guyana',
  'America/La_Paz',
  'America/Lima',
  'America/Paramaribo',
  'America/Scoresbysund',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Macquarie',
  'Antarctica/Mawson',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Vostok',
  'Asia/Almaty',
  'Asia/Anadyr',
  'Asia/Ashgabat',
  'Asia/Bangkok',
  'Asia/Bishkek',
  'Asia/Brunei',
  'Asia/Calcutta',
  'Asia/Choibalsan',
  'Asia/Dili',
  'Asia/Dushanbe',
  'Asia/Hong_Kong',
  'Asia/Hovd',
  'Asia/Jayapura',
  'Asia/Katmandu',
  'Asia/Kuala_Lumpur',
  'Asia/Magadan',
  'Asia/Makassar',
  'Asia/Manila',
  'Asia/Novosibirsk',
  'Asia/Sakhalin',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Tashkent',
  'Asia/Tbilisi',
  'Asia/Thimphu',
  'Asia/Ulaanbaatar',
  'Atlantic/South_Georgia',
  'Atlantic/Stanley',
  'Europe/Samara',
  'Indian/Chagos',
  'Indian/Christmas',
  'Indian/Cocos',
  'Indian/Kerguelen',
  'Indian/Mahe',
  'Indian/Maldives',
  'Indian/Mauritius',
  'Indian/Reunion',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Pacific/Efate',
  'Pacific/Enderbury',
  'Pacific/Fakaofo',
  'Pacific/Funafuti',
  'Pacific/Galapagos',
  'Pacific/Guadalcanal',
  'Pacific/Guam',
  'Pacific/Kosrae',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Palau',
  'Pacific/Ponape',
  'Pacific/Rarotonga',
  'Pacific/Tahiti',
  'Pacific/Truk',
  'Pacific/Wake',
  'Pacific/Wallis',
]

export enum BookingCalendarSteps {
  CalendarStep = 'calendarStep',
  FormStep = 'formStep',
}
