import styled, { css } from 'styled-components'
import { addOpacityToRGBA } from 'common/utils/colorUtils'
import { fontCss } from 'common/utils/styleUtilsNew'

export const CommonBookingCalendarStepsUi = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`

interface CommonBookingCalendarStepItemUiProps {
  isActive: boolean
  activeColor: string
  color?: string
}

export const CommonBookingCalendarStepItemUi = styled.div<CommonBookingCalendarStepItemUiProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  padding: 20px 10px;
  border-radius: 6px;
  border: 1px solid #b5c3d580;

  ${fontCss}

  background-color: ${p =>
    p.isActive ? addOpacityToRGBA(p.activeColor, 0.1) : 'inherit'};

  border-color: ${p => (p.isActive ? p.activeColor : '#b5c3d580')};

  & > span {
    font-weight: 600;
  }

  ${p =>
    p.theme.isEditorMobileMode &&
    css`
      padding: 5px;
      gap: 2px;
    `}

  ${p => p.theme.phone} {
    padding: 5px;
    gap: 2px;
  }
`
