import React from 'react'
import { CalendarStep } from 'common/components/entities/Calendar/calendar-step'
import { EventTimeZoneTypeEnum } from 'common/components/entities/Calendar/constants'
import {
  CommonBookingCalendarWrapperUi,
  CommonBookingCalendarContainerUi,
} from 'common/components/entities/Calendar/ui/common-calendar-container-ui'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { useBookingCalendarParams } from './use-booking-calendar-params'

interface PreviewBookingCalendarProps {
  entity: BookingCalendarInterface
}

export const PreviewBookingCalendar = ({
  entity,
}: PreviewBookingCalendarProps) => {
  const {
    event,
    timeSlots,
    isEventFetching,
    isTimeSlotsFetching,
    selectedDate,
    selectedTimeZone,
    selectedTimeSlot,
    selectedLocationId,
    handleChangeField,
    onMonthSelect,
    globalFontFamily,
    globalMobileFontSize,
    globalFontSize,
  } = useBookingCalendarParams(entity)

  return (
    <CommonBookingCalendarWrapperUi style={{ padding: '5px 0' }}>
      <CommonBookingCalendarContainerUi
        padding={entity.padding}
        margin={entity.margin}
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontFamily={globalFontFamily}
        fontSize={globalFontSize}
        mobileFontSize={globalMobileFontSize}
      >
        <CalendarStep
          availabilitySlots={timeSlots}
          selectedDate={selectedDate}
          onMonthSelect={onMonthSelect}
          onDateSelect={date => {
            handleChangeField('date', date)
            handleChangeField('timeSlot', '')
          }}
          selectedTimeSlot={selectedTimeSlot}
          onTimeSlotSelect={timeSlot => handleChangeField('timeSlot', timeSlot)}
          selectedTimeZone={selectedTimeZone}
          onTimeZoneSelect={tz => {
            if (event?.displayTimezoneType !== EventTimeZoneTypeEnum.Fixed) {
              handleChangeField('timeZone', tz)
            }
          }}
          selectedLocationId={selectedLocationId}
          onLocationSelect={locationId => {
            handleChangeField('eventLocationId', locationId)
          }}
          event={event}
          isLoading={isEventFetching || isTimeSlotsFetching}
          entity={entity}
          color={entity.color}
          mobileColor={entity.mobileColor}
          fontFamily={globalFontFamily}
          fontSize={globalFontSize}
          mobileFontSize={globalMobileFontSize}
        />
      </CommonBookingCalendarContainerUi>
    </CommonBookingCalendarWrapperUi>
  )
}
