import styled from 'styled-components'

export const CommonCalendarUi = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`
